@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');

/* @layer base {
  @font-face {
    font-family: 'Avenir Next';
    font-display: 'swap';
    font-weight: 400;
    src: url(../public/fonts/avenir-next/AvenirNext-LT-Pro-Regular.otf) format('opentype'),
      url(../public/fonts/avenir-next/AvenirNext-Regular.ttf) format('truetype'),
      url(../public/fonts/avenir-next-macos/._AvenirNext-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Avenir Next';
    font-display: 'swap';
    font-weight: 500;
    src: url(../public/fonts/avenir-next/AvenirNext-Medium.ttf) format('truetype'),
      url(../public/fonts/avenir-next-macos/._AvenirNext-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Avenir Next';

    font-display: 'swap';
    font-weight: 600;
    font-style: normal;
    line-height: 20px;
    src: url(../public/fonts/avenir-next/AvenirNext-DemiBold.ttf) format('truetype'),
      url(../public/fonts/avenir-next-macos/._AvenirNext-DemiBold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Avenir Next';
    font-display: 'swap';

    font-weight: 700;
    src: url(../public/fonts/avenir-next/AvenirNextLTPro-Bold.otf) format('opentype');
  }
  @font-face {
    font-family: 'Avenir Next';
    font-weight: 900;
    font-display: 'swap';

    src: url(../public/fonts/avenir-next/AvenirNext-Heavy.ttf) format('truetype'),
      url(../public/fonts/avenir-next-macos/._AvenirNext-Heavy.ttf) format('truetype');
  }
} */
body {
  width: 100%;
  /* font-family: 'Avenir Next' !important; */
  /* @apply font-body;
  @apply text-primary-black; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
}
