.slider {
  position: static;
}
.slider__container {
  position: relative;
  overflow: visible;
}

.slider-main-container {
  position: relative;
  overflow: visible;
}

.slider__container--fit {
  width: fit-content;
}
.slider__container--with-thumbs {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 25px;
  height: 480px;
}

.slider__slides {
  position: relative;
  overflow: hidden;
  padding: 0 20px;
}
.slider__thumbs {
  position: relative;
  box-sizing: border-box;
  height: 480px;
  width: 80px;
}
.swiper-pagination-bullet {
  border-radius: 0px !important;
  padding: 5px;
}

.swiper-pagination-bullet.slider__bullet--active {
  background: white;
  /* background: var(--Basalt, #2b2c39); */
  opacity: 1;
}
.slider__thumb {
  height: 80px;
  width: 80px;
}

.slider_arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
}

.slider_arrow .arrow {
  display: block;
  border: solid #494949;
  border-width: 0 2px 2px 0;
  padding: 10px;
  width: 0;
  height: 0;
}

.slider_arrow.slider_arrow_solid {
  padding: 16px 14px;
  width: 38px;
  overflow: hidden;
  display: flex;
  transition: 150ms ease-out;
}

.slider_arrow.slider_arrow_solid .arrow {
  border-color: #212121;
  border-width: 0 2px 2px 0;
}

.slider_arrow_next.slider_arrow_solid {
  justify-content: flex-end;
}

.slider_arrow_prev.slider_arrow_solid {
  justify-content: flex-start;
}

.slider_arrow_prev {
  left: -40px;
}

.slider_arrow_prev .arrow {
  transform: rotateZ(135deg);
}

.slider_arrow_next .arrow {
  transform: rotateZ(-45deg);
}

.slider_arrow_container.slider_arrow_solid {
  background-color: #212121;
  padding: 12px;
}

.slider_arrow_prev.slider_arrow_inner {
  left: 10px;
}

.slider_arrow_inner:hover {
  background-color: white;
  padding: 20px;
}

.product-slider-container .slider_arrow_inner:hover {
  background-color: #e5e9eb;
  padding: 20px;
}

.hero-slider-container .slider_arrow_inner:hover {
  background-color: #e5e9eb;
  padding: 20px;
}

.hero-slider-container .slider_arrow_inner {
  background-color: white;
}

.swiper-button-disabled .arrow {
  border-color: #212121;
}

.swiper-slide {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

video-container iframe html body video {
  margin-bottom: 0px;
}

.list-container ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
}
.list-container ul {
  list-style: none;
}

.list-container li {
  position: relative;
  padding-left: 20px;
}

.our-product-container {
  max-width: 1520px;
  margin: 0 auto;
}
.container {
  max-width: 1680px;
}
.ReactModal__Body--open {
  overflow: hidden !important;
}
.markdown-content a{
  color: #337ab7;
}
.markdown-content #info {
  position: fixed;
  top: 0;
  z-index: 50;
  margin-top: 20px;
  background-color: white;
}
@media only screen and (max-width: 500px) {
  .markdown-content #info {
   max-width: 280px;

  }
  .modal-height{
    max-Height: 'calc(200vh - 80px)';
  }
}
@media only screen and (max-width: 430px) {
  .markdown-content #info {
   max-width: 220px;
  }
  .modal-height{
    max-Height: 'calc(200vh - 80px)';
  }
}

.modal-open {
  overflow-y: hidden;
}

.markdown-content p:empty {
  display: block;
  min-height: 1em; /* Adjust this value as needed */
  /* Alternatively, you can use padding */
  /* padding: 0.5em 0; */
}
.markdown-content p {
  padding-top:5px
}

/* .custom-select option {
  padding: 8px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
} */

@media only screen and (min-width: 800px) {
  .image-content .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 60% !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 90% !important;
  }
  .mardown-container figure div img {
    padding-top: 48px;
    margin: 0 auto;
  }
  .title .mardown-container p {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1024px) {
  .image-container p img {
    padding-top: 79px;
    padding-bottom: 28px;
    width: 214px;
    display: inline-flex;
  }
  .title .mardown-container p {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1023px) {
  .image-content .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 65% !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 90% !important;
  }
  .mardown-container figure div img {
    padding-top: 48px;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .image-content .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 25rem !important;
  }
  .hero-slider-container .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 31rem !important;
    bottom: unset !important;
  }
  .image-container p img {
    padding-top: 24px;
    padding-bottom: 24px;
    width: 267px;
    display: inline-flex;
  }
  .mardown-container figure div img {
    padding-top: 48px;
    width: 100%;
  }
  .mardown-container figure div figcaption {
    padding-left: 12px;
    padding-right: 12px;
  }
  .mardown-container ul {
    padding-left: 48px;
    padding-right: 48px;
  }
  .mardown-container ol {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mardown-container ol li strong{
    padding-left: 0px;
    padding-right: 0px;
  }
  .mardown-container ul li p {
    padding-left: 0px;
    padding-right: 0px;
  }
  .title {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mardown-container p {
    padding-left: 24px;
    padding-right: 24px;
  }
  .sidebar-dropdown {
    margin-top: 37rem;
  }
}
@media only screen and (max-width: 500px) {
  .image-container p img {
    padding-top: 37px;
    padding-bottom: 24px;
    width: 324px;
    display: inline-flex;
  }
  .hero-slider-container .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 16rem !important;
  }
  .image-content .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 39% !important;
  }
  .pdp-slider .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 85% !important;
  }
  .mardown-container figure div figcaption {
    padding-left: 12px;
    padding-right: 12px;
  }
  .second-figcaption {
    padding: 20px; /* Adjust the padding value as needed */
  }
  .mardown-container ul {
    padding-left: 48px;
    padding-right: 48px;
  }
  .mardown-container ol {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mardown-container ol li strong{
    padding-left: 0px;
    padding-right: 0px;
  }
  .mardown-container ul li p {
    padding-left: 0px;
    padding-right: 0px;
  }
  .title {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mardown-container figure div img {
    padding-top: 32px;
    margin: 0 auto;
  }
  .sidebar-dropdown {
    margin-top: 22rem;
  }
}

@media only screen and (max-width: 1280px) {
  .image-container p img {
    padding-top: 79px;
    padding-bottom: 28px;
    width: 214px;
    display: inline-flex;
  }
}

@media only screen and (min-width: 1280px) {
  .image-container p img {
    padding-top: 79px;
    padding-bottom: 28px;
    width: 214px;
    display: inline-flex;
  }
}

@media only screen and (max-width: 1366px) {
  .image-container p img {
    padding-top: 37px;
    padding-bottom: 32px;
    width: 265px;
    display: inline-flex;
  }
  .container-markdown img {
    height: 9rem;
  }
}

@media only screen and (max-width: 1920px) {
  .image-container p img {
    width: 333px;
    padding-bottom: 32px;
    display: inline-flex;
  }
}

@media only screen and (min-width: 1920px) {
  .image-container p img {
    width: 333px;
    padding-bottom: 32px;
    display: inline-flex;
  }
  .mardown-container figure div img {
    padding-top: 80px;
    margin: 0 auto;
  }
  .mardown-container figure div {
    display: block !important;
  }
}

#configure-button {
  position: fixed;
  top: 15px;
  left: 25px;
  width: 70px;
}

.mardown-container figure div figcaption {
  text-align: left !important;
  color: #666666;
  font-size: 14px;
  padding-top: 14px;
}

/* .markdown-mega-menu img{
  max-height: 132px;
} */
